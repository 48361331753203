import React, { useState,useEffect } from 'react';
import { Card, Table, Button, Drawer } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Register from '../../../components/EquipmentCheck/EquipmentCheckIn';
import DataTable from '../../../components/data-display/table/DataTable';
import moment from 'moment';
import {FetchLocalData, TextCapitalize,TextUpperCase,IndustryType } from '../../../utils/helper'; 
import FoodCheckin from '../../../components/EquipmentCheck/FoodCheckin';
import ActionButton from "../../../images/new/action_icon.svg"
const WashList = (props) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  let tableColumns=[]
  if(IndustryType()!=="Food_Grade"){
    tableColumns=[
      {name: "EQUIPMENT NO.",label: "Equipment No.",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
      {name: "CUSTOMER",label: "Customer",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "EQUIPMENT TYPE",label: "Equipment Type",options: {filter: true,sort: true,customBodyRender: value =>value}},
      {name: "CHECK IN",label: "Check In",options: {filter: true,sort: true,}},
      {name: "10 DAYS ALERT",label: "Alert",options: {filter: true,sort: true,customBodyRender: value => {
        return <div>
        {(()=>{
          if(value>=0 && value<=5){
            return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#0FA36824",color:"#0FA368",marginRight:"5px"}}>{value}</span>
          }else if(value>=6 && value<=10){
            return <span style={{padding:"0px 6px",borderRadius:"2px",background:"FD8F4624 ",color:"#FD8F46",marginRight:"5px"}}>{value}</span>
          }else if(value>=11){
            return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#A30F0F24",color:"#D80B0B",marginRight:"5px"}}>{value}</span>
          }
        })()}
        Days
    </div>
      },}},
      {name: "PO NUMBER",label: "PO No.",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
      {name: "FROM/GENERATOR",label: "Generator",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "JOBSITE",label: "Jobsite",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "DROP OFF CARRIER",label: "Drop of Carrier",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "DROP OFF DRIVER",label: "Drop of Driver",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "WASH STATUS",label: "Wash Status",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "ACTION",label: "Action",options: {filter: false,sort: true,setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          right: "0",
          background: "#fff",
          zIndex: 100,
          textAlign: 'center',
        }
      }),
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          right: 0,
          color:'white',
          // background: "#1890ffd1",
          zIndex: 101
        }
      })}},
    ]
  }else{
 tableColumns=[
      {name: "TRAILER UNIT NO.",label: "Trailer Unit No.",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
      {name: "TRUCK NO.",label: "Truck No.",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
      {name: "CUSTOMER",label: "Customer",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "CHECK IN",label: "Check In",options: {filter: true,sort: true,}},
      {name: "10 DAYS ALERT",label: "Alert",options: {filter: true,sort: true,customBodyRender: value => {
        return <div>
        {(()=>{
          if(value>=0 && value<=5){
            return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#0FA36824",color:"#0FA368",marginRight:"5px"}}>{value}</span>
          }else if(value>=6 && value<=10){
            return <span style={{padding:"0px 6px",borderRadius:"2px",background:"FD8F4624 ",color:"#FD8F46",marginRight:"5px"}}>{value}</span>
          }else if(value>=11){
            return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#A30F0F24",color:"#D80B0B",marginRight:"5px"}}>{value}</span>
          }
        })()}
        Days
    </div>
      },}},
      {name: "PO NUMBER",label: "PO No.",options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}},
      {name: "DROP OFF DRIVER",label: "Drop of Driver",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "WASH STATUS",label: "Wash Status",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
      {name: "ACTION",label: "Action",options: {filter: false,sort: true,setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          right: "0",
          background: "#fff",
          zIndex: 100,
          textAlign: 'center',
        }
      }),
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          right: 0,
          color:'white',
          // background: "#1890ffd1",
          zIndex: 101
        }
      })}},
    ]
  }
  const tableData=[]
  props.yardList &&
   props.yardList.map((item, index) => {
    if(IndustryType()!=="Food_Grade"){
      tableData.push([
        item.asset_details.equipment_no,
        item.customer_details.name,
        FetchLocalData(item.asset_details?.equipment_type_details?.parent_id,item.asset_details?.equipment_type_details?.param_value),
        `${moment.utc(moment(item.check_in).toISOString()).format('MM/DD/YYYY')}`,
        
        item.ten_days_alert,
        item.po_details ? item.po_details.po_no : 'NA',
       item.asset_txns_details[1]
          ? item.asset_txns_details[1].field_value
          : 'NA',
      item.job_site ? item.job_site : 'NA',
      item.asset_txns_details[0]
          ? item.asset_txns_details[0].field_value
          : 'NA',
    item.driver_name ? item.driver_name : 'NA',
    item.asset_txns_status?.param_description === "Check-In For Asset" ? "PRE-WASH" :item.asset_txns_status?.param_description.toUpperCase(),
       <>
<Register
            asset_txns_id={item.asset_txns_id}
            status={item.asset_txns_status?.param_description}
            {...props}
            custom={true}
            Button={<img src={ActionButton} alt="icon"/>}
            title="Approve Check-In Equipment"
          />  </> 
          ]);
        }else{
          tableData.push([
            item.asset_details.equipment_no,
            item.truck_number ? item.truck_number: 'NA',
            item.customer_details.name,
            `${moment.utc(moment(item.check_in).toISOString()).format('MM/DD/YYYY')}`,
            
            item.ten_days_alert,
            item.po_details ? item.po_details.po_no : 'NA',
        item.driver_name ? item.driver_name : 'NA',
        item.asset_txns_status?.param_description === "Check-In For Asset" ? "PRE-WASH" :item.asset_txns_status?.param_description.toUpperCase(),
              <FoodCheckin
                asset_txns_id={item.asset_txns_id}
                status={item.asset_txns_status?.param_description}
                {...props}
                custom={true}
                Button={<img src={ActionButton} alt="icon"/>}
                title="Approve Check-In Equipment"
              />
              ]);
        }
    });
    
  const showDrawer = (data) => {
    setIsDrawerVisible(true);
  };
const yardNav=[{id:0,name:"pre wash"},{id:1,name:"in yard"},{id:2,name:"picked up"},]
  return (
    <div>
        <DataTable 
        onPageChange={props.onPageChange}
        urlParam="preWash"
        count={props.count}
        loader={props.loading}
        title={
        <div className='wash_nav_yard_section'>
          {yardNav.map((item)=>{
            return <span id={item.id} onClick={()=>props.inYardTableNav(item.id)} style={{color:props.tab===item.id?'#FD8F46':'',borderBottom:props.tab===item.id?'2px solid #FD8F46':''}}>{item.name}</span>
          })}
        </div>
        }
        header={tableColumns}
        data={tableData}
        rowKey="id" />
      <Drawer
        className="drawer"
        title="Basic Drawer"
        placement="right"
        // closable={false}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </div>
  );
};
export default WashList;
